<template>
    <painel titulo="Cobranças PIX" icone="pi pi-money-bill" :refreshFunction="atualizar" v-show="mostrarListagem">
        <filtros @atualizarFiltro="filtro = $event"></filtros>
        <tabela
            :data="cobrancasPix"
            dataKey="cobrancaPixId"
            :globalFilterFields="['cobrancaId', 'usuarioSolicitacao', 'clienteId', 'descricaoCobranca', 'conta', 'statusPix', 'statusDescr']"
            stateKey="dt-pix"
        >
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :cobrancaPix="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="dataSolicitacao" header="Data Solicitação" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataSolicitacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="cobrancaPixId" header="Id" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.cobrancaPixId }}
                    </template>
                </Column>
                <Column field="usuarioSolicitacao" header="Solicitante" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.usuarioSolicitacao }}
                    </template>
                </Column>
                <Column field="clienteId" header="Identificador" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.clienteId }}
                    </template>
                </Column>
                <Column field="descricaoCobranca" header="Descrição" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.descricaoCobranca }}
                    </template>
                </Column>
                <Column field="valor" header="Valor" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap text-right">
                            <span>{{ $numeral(slotProps.data.valor).format('$0,0.00') }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="conta" header="Conta" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.conta }}
                    </template>
                </Column>
                <Column field="status" header="Status PIX" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusPix"></status>
                    </template>
                </Column>
                <Column field="status" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
                <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import PixServices from './services';
import BtnDetalhar from './BtnDetalhar';
import Filtros from './ListagemFiltros';

export default {
    components: {
        Filtros,
        BtnDetalhar,
    },

    data() {
        return {
            cobrancasPix: null,
            filtro: null,
            carregado: false,
        };
    },

    methods: {
        obterCobrancasPix() {
            this.$store.dispatch('addRequest');
            PixServices.obterTodas(this.filtro).then((response) => {
                if (response?.success) {
                    this.cobrancasPix = response.data;
                    this.carregado = true;
                } else {
                    this.cobrancasPix = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterCobrancasPix();
        },
    },

    mounted() {
        this.obterCobrancasPix();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Pagamentos_Pix';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Pagamentos_Pix') {
                if (this.$store.getters.atualizar) {
                    this.obterCobrancasPix();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        filtro(next, prev) {
            if (this.carregado && next.dataSolicitadoDe != prev.dataSolicitadoDe && next.dataSolicitadoAte != prev.dataSolicitadoAte) {
                this.obterCobrancasPix();
            }
        },
    },
};
</script>
