<template>
    <Panel header="Filtros" :toggleable="true" class="filtro">
        <div class="formgrid grid p-fluid mt-2">
            <div class="field col-12 md:col-3 lg:col-2">
                <label>Data Solicitação (DE)</label>
                <Calendar v-model="dataSolicitacaoDe" :manualInput="false" />
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <label>Data Solicitação (ATÉ)</label>
                <Calendar v-model="dataSolicitacaoAte" :manualInput="false" />
            </div>
        </div>
    </Panel>
</template>

<script>
export default {
    data() {
        return {
            dataSolicitacaoDe: null,
            dataSolicitacaoAte: null,
        };
    },

    emits: ['atualizarFiltro'],

    methods: {
        atualizarFiltro() {
            let filtro = {
                dataSolicitacaoDe: this.dataSolicitacaoDe ? this.$moment(this.dataSolicitacaoDe).format('YYYY-MM-DD') : '',
                dataSolicitacaoAte: this.dataSolicitacaoAte ? this.$moment(this.dataSolicitacaoAte).format('YYYY-MM-DD') : '',
            };
            this.$emit('atualizarFiltro', filtro);
        },
    },

    created() {
        this.dataSolicitacaoAte = new Date();
        this.dataSolicitacaoDe = new Date(this.$moment(this.dataInteracaoAte).subtract(2, 'months').format('YYYY-MM-DD'));
    },

    watch: {
        dataSolicitacaoDe() {
            this.atualizarFiltro();
        },
        dataSolicitacaoAte() {
            this.atualizarFiltro();
        },
    },
};
</script>